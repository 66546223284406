import { BlocksContent } from "@strapi/blocks-react-renderer";

export const REGISTER_CUSTOMER_REQUEST = "REGISTER_CUSTOMER_REQUEST";
export const CLEAR_USER_ERROR = "CLEAR_USER_ERROR";
export const REGISTER_CUSTOMER_SUCCESS = "REGISTER_CUSTOMER_SUCCESS";
export const REGISTER_CUSTOMER_FAIL = "REGISTER_CUSTOMER_FAIL";
export const CREATE_GROUP_REQUEST = "CREATE_GROUP_REQUEST";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAIL = "CREATE_GROUP_FAIL";
export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_LOGGED_IN = "SET_LOGGED_IN";
export const SET_LOADING = "SET_LOADING";
export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_EMPLOYER_FAIL";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const DELETE_SERVICE_REQUEST = "DELETE_SERVICE_REQUEST";
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS";
export const DELETE_SERVICE_FAIL = "DELETE_SERVICE_FAIL";
export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL";
export const UPDATE_GROUP_REQUEST = "UPDATE_GROUP_REQUEST";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_FAIL = "UPDATE_GROUP_FAIL";
export const SEND_EMAIL_MEMBER_REQUEST = "SEND_EMAIL_MEMBER_REQUEST";
export const SEND_EMAIL_MEMBER_SUCCESS = "SEND_EMAIL_MEMBER_SUCCESS";
export const SEND_EMAIL_MEMBER_FAIL = "SEND_EMAIL_MEMBER_FAIL";
export const SEND_EMAIL_CUSTOMER_REQUEST = "SEND_EMAIL_CUSTOMER_REQUEST";
export const SEND_EMAIL_CUSTOMER_SUCCESS = "SEND_EMAIL_CUSTOMER_SUCCESS";
export const SEND_EMAIL_CUSTOMER_FAIL = "SEND_EMAIL_CUSTOMER_FAIL";
export const CREATE_SERVICE_REQUEST = "CREATE_SERVICE_REQUEST";
export const CREATE_SERVICE_SUCCESS = "CREATE_SERVICE_SUCCESS";
export const CREATE_SERVICE_FAIL = "CREATE_SERVICE_FAIL";
export const GET_SERVICES_REQUEST = "GET_SERVICES_REQUEST";
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_FAIL = "GET_SERVICES_FAIL";
export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";
export const GET_SHIPPING_REQUEST = "GET_SHIPPING_REQUEST";
export const GET_SHIPPING_SUCCESS = "GET_SHIPPING_SUCCESS";
export const GET_SHIPPING_FAIL = "GET_SHIPPING_FAIL";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const RESET_CART = "RESET";
export const RESET_BLOG = "RESET_BLOG";
export const RESET_COUPONS = "RESET_COUPONS";
export const RESET_USERS = "RESET_USERS";
export const RESET_ORDERS = "RESET_ORDERS";
export const SAVE_SHIPPING_ADDRESS = "SAVE_SHIPPING_ADDRESS";
export const SAVE_PAYMENT_METHOD = "SAVE_PAYMENT_METHOD";
export const SAVE_STRIPE_PAYMENT_METHOD = "SAVE_STRIPE_PAYMENT_METHOD";
export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL";
export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL";
export const SEND_CONTACT_MAIL_REQUEST = "SEND_CONTACT_MAIL_REQUEST";
export const SEND_CONTACT_MAIL_SUCCESS = "SEND_CONTACT_MAIL_SUCCESS";
export const SEND_CONTACT_MAIL_FAIL = "SEND_CONTACT_MAIL_FAIL";
export const SET_COUPON = "SET_COUPON";
export const DELETE_ALERT_REQUEST = "DELETE_ALERT_REQUEST";
export const DELETE_ALERT_SUCCESS = "DELETE_ALERT_SUCCESS";
export const DELETE_ALERT_FAIL = "DELETE_ALERT_FAIL";
export const CREATE_ALERT_REQUEST = "CREATE_ALERT_REQUEST";
export const CREATE_ALERT_SUCCESS = "CREATE_ALERT_SUCCESS";
export const CREATE_ALERT_FAIL = "CREATE_ALERT_FAIL";
export const GET_ALERTS_REQUEST = "GET_ALERTS_REQUEST";
export const GET_ALERTS_SUCCESS = "GET_ALERTS_SUCCESS";
export const GET_ALERTS_FAIL = "GET_ALERTS_FAIL";
export const DELETE_COUPON_REQUEST = "DELETE_COUPON_REQUEST";
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS";
export const DELETE_COUPON_FAIL = "DELETE_COUPON_FAIL";
export const CREATE_COUPON_REQUEST = "CREATE_COUPON_REQUEST";
export const CREATE_COUPON_SUCCESS = "CREATE_COUPON_SUCCESS";
export const CREATE_COUPON_FAIL = "CREATE_COUPON_FAIL";
export const GET_COUPONS_REQUEST = "GET_COUPONS_REQUEST";
export const GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS";
export const GET_COUPONS_FAIL = "GET_COUPONS_FAIL";
export const DELETE_BLOG_REQUEST = "DELETE_BLOG_REQUEST";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_FAIL = "DELETE_BLOG_FAIL";
export const CREATE_BLOG_REQUEST = "CREATE_BLOG_REQUEST";
export const CREATE_BLOG_SUCCESS = "CREATE_BLOG_SUCCESS";
export const CREATE_BLOG_FAIL = "CREATE_BLOG_FAIL";
export const GET_BLOGS_REQUEST = "GET_BLOGS_REQUEST";
export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS";
export const GET_BLOGS_FAIL = "GET_BLOGS_FAIL";
export const DELETE_SENIOR_HOME_REQUEST = "DELETE_SENIOR_HOME_REQUEST";
export const DELETE_SENIOR_HOME_SUCCESS = "DELETE_SENIOR_HOME_SUCCESS";
export const DELETE_SENIOR_HOME_FAIL = "DELETE_SENIOR_HOME_FAIL";
export const CREATE_SENIOR_HOME_REQUEST = "CREATE_SENIOR_HOME_REQUEST";
export const CREATE_SENIOR_HOME_SUCCESS = "CREATE_SENIOR_HOME_SUCCESS";
export const CREATE_SENIOR_HOME_FAIL = "CREATE_SENIOR_HOME_FAIL";
export const GET_SENIOR_HOMES_REQUEST = "GET_SENIOR_HOMES_REQUEST";
export const GET_SENIOR_HOMES_SUCCESS = "GET_SENIOR_HOMES_SUCCESS";
export const GET_SENIOR_HOMES_FAIL = "GET_SENIOR_HOMES_FAIL";
export const GET_NURSES_FAIL = "GET_NURSES_FAIL";
export const GET_NURSES_REQUEST = "GET_NURSES_REQUEST";
export const GET_NURSES_SUCCESS = "GET_NURSES_SUCCESS";
export const GET_NURSE_PROFILE_REQUEST = "GET_NURSE_PROFILE_REQUEST";
export const GET_NURSE_PROFILE_SUCCESS = "GET_NURSE_PROFILE_SUCCESS";
export const GET_NURSE_PROFILE_FAILED = "GET_NURSE_PROFILE_FAILED";
export const ADD_NURSE_REQUEST = "ADD_NURSE_REQUEST";
export const ADD_NURSE_SUCCESS = "ADD_NURSE_SUCCESS";
export const ADD_NURSE_FAILED = "ADD_NURSE_FAILED";
export const UPDATE_NURSE_REQUEST = "UPDATE_NURSE_REQUEST";
export const UPDATE_NURSE_SUCCESS = "UPDATE_NURSE_SUCCESS";
export const UPDATE_NURSE_FAILED = "UPDATE_NURSE_FAILED";
export const DELETE_NURSE_REQUEST = "DELETE_NURSE_REQUEST";
export const DELETE_NURSE_REQUEST_SUCCESS = "DELETE_NURSE_REQUEST_SUCCESS";
export const DELETE_NURSE_REQUEST_FAILED = "DELETE_NURSE_REQUEST_FAILED";
export const ASSIGNED_SENIOR_REQUEST = "ASSIGNED_SENIOR_REQUEST";
export const ASSIGNED_SENIOR_SUCCESS = "ASSIGNED_SENIOR_SUCCESS";
export const ASSIGNED_SENIOR_FAILED = "ASSIGNED_SENIOR_FAILED";
export const ASSIGNED_SENIOR_LIST_REQUEST = "ASSIGNED_SENIOR_LIST_REQUEST";
export const ASSIGNED_SENIOR_LIST_SUCCESS = "ASSIGNED_SENIOR_LIST_SUCCESS";
export const ASSIGNED_SENIOR_LIST_FAILED = "ASSIGNED_SENIOR_LIST_FAILED";
export const SIGNUP_NURSE_REQUSET = "SIGNUP_NURSE_REQUSET";
export const SIGNUP_NURSE_SUCCESS = "SIGNUP_NURSE_SUCCESS";
export const SIGNUP_NURSE_FAILED = "SIGNUP_NURSE_FAILED";
export const GET_ITSUPPORT_LIST_REQUEST = "GET_ITSUPPORT_LIST_REQUEST";
export const GET_ITSUPPORT_LIST_SUCCESS = "GET_ITSUPPORT_LIST_SUCCESS";
export const GET_ITSUPPORT_LIST_FAIL = "GET_ITSUPPORT_LIST_FAIL";
export const GET_ITSUPPORT_PROFILE_REQUEST = "GET_ITSUPPORT_PROFILE_REQUEST";
export const GET_ITSUPPORT_PROFILE_SUCCESS = "GET_ITSUPPORT_PROFILE_SUCCESS";
export const GET_ITSUPPORT_PROFILE_FAIL = "GET_ITSUPPORT_PROFILE_FAIL";
export const UPDATE_ITSUPPORT_PROFILE_REQUEST = "UPDATE_ITSUPPORT_PROFILE_REQUEST";
export const UPDATE_ITSUPPORT_PROFILE_SUCCESS = "UPDATE_ITSUPPORT_PROFILE_SUCCESS";
export const UPDATE_ITSUPPORT_PROFILE_FAIL = "UPDATE_ITSUPPORT_PROFILE_FAIL";
export const SEND_INVITE_ITSUPPORT_REQUEST = "SEND_INVITE_ITSUPPORT_REQUEST";
export const SEND_INVITE_ITSUPPORT_SUCCESS = "SEND_INVITE_ITSUPPORT_SUCCESS";
export const SEND_INVITE_ITSUPPORT_FAIL = "SEND_INVITE_ITSUPPORT_FAIL";
export const SIGNUP_ITSUPPORT_REQUEST = "SIGNUP_ITSUPPORT_REQUEST";
export const SIGNUP_ITSUPPORT_SUCCESS = "SIGNUP_ITSUPPORT_SUCCESS";
export const SIGNUP_ITSUPPORT_FAIL = "SIGNUP_ITSUPPORT_FAIL";
export const GET_USER_EDIT_FORM_DATA_REQUEST = "GET_USER_EDIT_FORM_DATA_REQUEST";
export const GET_USER_EDIT_FORM_DATA_SUCCESS = "GET_USER_EDIT_FORM_DATA_SUCCESS";
export const GET_USER_EDIT_FORM_DATA_FAIL = "GET_USER_EDIT_FORM_DATA_FAIL";
export const SET_USER_EDIT_FORM_DATA_REQUEST = "SET_USER_EDIT_FORM_DATA_REQUEST";
export const SET_USER_EDIT_FORM_DATA_SUCCESS = "SET_USER_EDIT_FORM_DATA_SUCCESS";
export const SET_USER_EDIT_FORM_DATA_FAIL = "SET_USER_EDIT_FORM_DATA_FAIL";
export const RESET_ITSUPPORT_MESSAGE = "RESET_ITSUPPORT_MESSAGE";
export const SET_ITSUPPORT_MESSAGE = "SET_ITSUPPORT_MESSAGE";
export const RESET_NURSE_MESSAGE = "RESET_NURSE_MESSAGE";
export const SET_NURSE_MESSAGE = "SET_NURSE_MESSAGE";
export const UPDATE_USER_EDIT_REQUEST = "UPDATE_USER_EDIT_REQUEST";
export const UPDATE_USER_EDIT_REQUEST_SUCCESS = "UPDATE_USER_EDIT_REQUEST_SUCCESS";
export const UPDATE_USER_EDIT_REQUEST_FAIL = "UPDATE_USER_EDIT_REQUEST_FAIL";
export const DELETE_IT_SUPPORT_PROFILE_REQUEST = "DELETE_IT_SUPPORT_PROFILE_REQUEST";
export const DELETE_IT_SUPPORT_PROFILE_REQUEST_SUCCESS = "DELETE_IT_SUPPORT_PROFILE_REQUEST_SUCCESS";
export const DELETE_IT_SUPPORT_PROFILE_REQUEST_FAILED = "DELETE_IT_SUPPORT_PROFILE_REQUEST_FAILED";
export const JOIN_ROOM_REQUEST = "JOIN_ROOM_REQUEST";
export const JOIN_ROOM_REQUEST_SUCCESS = "JOIN_ROOM_REQUEST_SUCCESS";
export const JOIN_ROOM_REQUEST_FAIL = "JOIN_ROOM_REQUEST_FAIL";
export const RESET_VIDEO_MEET = "RESET_VIDEO_MEET";
export const SET_LK_TOKEN = "SET_LK_TOKEN";
export const SET_PRE_JOIN_TOKEN = "SET_PRE_JOIN_TOKEN";
export const REMOVE_ROOM_NAME = "REMOVE_ROOM_NAME";

// Credential
export type Credential = {
    username: string;
    password: string;
};

// User
export type User = {
    id: number | null;
    username: string;
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    mobileNumber?: string;
    skypeEmail?: string;
    image?: string;
    nursingHome?: string;
    department?: string;
    homeAddress?: string;
    homeCity?: string;
    homePostalCode?: string;
    nursingHomeAddress?: string;
    role?: string;
    isAdmin: boolean;
    readOnly: boolean;
    group: Group;
    orders?: any[];
    isSenior: boolean;
    loading: boolean;
    error: any;
    stripePublicKey: string;
    tabletLanguage: any;
    autoAnswer: any;
    serviceOption: any;
    chosenService: any;
};

export type UserState = User & {
    isLoggedIn: boolean;
};

export type Users = {
    list: any[];
    loading: boolean;
    error: any;
    profile: {};
};

export type Orders = {
    list: any[];
    loading: boolean;
    error: any;
};

// Customer
export type Customer = {
    username: string;
    firstName: string;
    lastName: string;
    mobileNumber?: string;
    skypeEmail?: string;
    image?: string;
    role?: string;
    isAdmin: boolean;
};

// Register customer
export type RegisterCustomerRequestAction = {
    type: typeof REGISTER_CUSTOMER_REQUEST;
    payload: {
        username: string;
        email: string;
        password: string;
        firstName: string;
        lastName: string;
        role: string;
        history: any;
        redirect: any;
    };
};

// Clear user error
export type ClearUserErrorAction = {
    type: typeof CLEAR_USER_ERROR;
    payload: {};
};

export type RegisterCustomerSuccessAction = {
    type: typeof REGISTER_CUSTOMER_SUCCESS;
    payload: {
        username: string;
        email: string;
        password: string;
        firstName: string;
        lastName: string;
        role: string;
    };
};

export type RegisterCustomerFailAction = {
    type: typeof REGISTER_CUSTOMER_FAIL;
    payload: {
        error: any;
    };
};

// Login
export type LoginUserRequestAction = {
    type: typeof LOGIN_USER_REQUEST;
    payload: {
        credential: {
            username: string;
            password: string;
        };
        redirect: any;
        history: any;
    };
};

export type LoginUserSuccessAction = {
    type: typeof LOGIN_USER_SUCCESS;
    payload: User;
};

export type LoginUserFailAction = {
    type: typeof LOGIN_USER_FAIL;
    payload: {
        error: any;
    };
};

export type LogoutUserAction = {
    type: typeof LOGOUT_USER;
};

export type SetLoggedInAction = {
    type: typeof SET_LOGGED_IN;
};

export type SetLoadingAction = {
    type: typeof SET_LOADING;
};

// Get users
export type GetUsersRequestAction = {
    type: typeof GET_USERS_REQUEST;
};

export type GetUsersSuccessAction = {
    type: typeof GET_USERS_SUCCESS;
    payload: User;
};

export type GetUsersFailAction = {
    type: typeof GET_USERS_FAIL;
    payload: {
        error: any;
    };
};

export type GetOrdersRequestAction = {
    type: typeof GET_ORDERS_REQUEST;
};

export type GetOrdersSuccessAction = {
    type: typeof GET_ORDERS_SUCCESS;
    payload: Order;
};

export type GetOrdersFailAction = {
    type: typeof GET_ORDERS_FAIL;
    payload: {
        error: any;
    };
};

// Get user
export type GetUserRequestAction = {
    type: typeof GET_USER_REQUEST;
};

export type GetUserSuccessAction = {
    type: typeof GET_USER_SUCCESS;
    payload: User;
};

export type GetUserFailAction = {
    type: typeof GET_USER_FAIL;
    payload: {
        error: any;
    };
};

// Update user
export type UserUpdate = {
    id: any;
    username: string;
    email: string;
    password?: string;
    firstName: string;
    lastName: string;
    mobileNumber?: string;
    skypeEmail?: string;
    nursingHome?: string;
    department?: string;
    homeAddress?: string;
    homeCity?: string;
    homePostalCode?: string;
    nursingHomeAddress?: string;
    image?: string;
    role?: string;
    isAdmin: boolean;
    error: any;
    success: any;
    isSenior: boolean;
    tabletLanguage?: string;
    autoAnswer?: string;
    serviceOption?: string;
    chosenService?: string;
    relativeDoneEmailId?: number;
};

export type UpdateUserRequestAction = {
    type: typeof UPDATE_USER_REQUEST;
    payload: Partial<UserUpdate>;
};

export type UpdateUserSuccessAction = {
    type: typeof UPDATE_USER_SUCCESS;
    payload: User;
};

export type UpdateUserFailAction = {
    type: typeof UPDATE_USER_FAIL;
    payload: {
        error: any;
    };
};

export type DeleteUserRequestAction = {
    type: typeof DELETE_USER_REQUEST;
    payload: User;
};

export type DeleteUserSuccessAction = {
    type: typeof DELETE_USER_SUCCESS;
};

export type DeleteUserFailAction = {
    type: typeof DELETE_USER_FAIL;
    payload: {
        error: any;
    };
};

export type DeleteOrderRequestAction = {
    type: typeof DELETE_ORDER_REQUEST;
    payload: Order;
};

export type DeleteOrderSuccessAction = {
    type: typeof DELETE_ORDER_SUCCESS;
};

export type DeleteOrderFailAction = {
    type: typeof DELETE_ORDER_FAIL;
    payload: {
        error: any;
    };
};

export type DeleteServiceRequestAction = {
    type: typeof DELETE_SERVICE_REQUEST;
    payload: ServiceItem;
};

export type DeleteServiceSuccessAction = {
    type: typeof DELETE_SERVICE_SUCCESS;
};

export type DeleteServiceFailAction = {
    type: typeof DELETE_SERVICE_FAIL;
    payload: {
        error: any;
    };
};

export type DeleteAlertRequestAction = {
    type: typeof DELETE_ALERT_REQUEST;
    payload: AlertItem;
};

export type DeleteAlertSuccessAction = {
    type: typeof DELETE_ALERT_SUCCESS;
};

export type DeleteAlertFailAction = {
    type: typeof DELETE_ALERT_FAIL;
    payload: {
        error: any;
    };
};

export type DeleteSeniorHomeRequestAction = {
    type: typeof DELETE_SENIOR_HOME_REQUEST;
    payload: SeniorHomeItem;
};

export type DeleteSeniorHomeSuccessAction = {
    type: typeof DELETE_SENIOR_HOME_SUCCESS;
};

export type DeleteSeniorHomeFailAction = {
    type: typeof DELETE_SENIOR_HOME_FAIL;
    payload: {
        error: any;
    };
};

export type DeleteBlogRequestAction = {
    type: typeof DELETE_BLOG_REQUEST;
    payload: BlogItem;
};

export type DeleteBlogSuccessAction = {
    type: typeof DELETE_BLOG_SUCCESS;
};

export type DeleteBlogFailAction = {
    type: typeof DELETE_BLOG_FAIL;
    payload: {
        error: any;
    };
};

export type DeleteCouponRequestAction = {
    type: typeof DELETE_COUPON_REQUEST;
    payload: CouponItem;
};

export type DeleteCouponSuccessAction = {
    type: typeof DELETE_COUPON_SUCCESS;
};

export type DeleteCouponFailAction = {
    type: typeof DELETE_COUPON_FAIL;
    payload: {
        error: any;
    };
};

export type CreateGroupRequestAction = {
    type: typeof CREATE_GROUP_REQUEST;
    payload: GroupState;
};

export type CreateGroupSuccessAction = {
    type: typeof CREATE_GROUP_SUCCESS;
    payload: Group;
};

export type CreateGroupFailAction = {
    type: typeof CREATE_GROUP_FAIL;
    payload: {
        error: any;
    };
};

export type GroupUpdate = {
    id: any;
    username: string;
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    mobileNumber?: string;
    skypeEmail?: string;
    image?: string;
    nursingHome?: string;
    department?: string;
    homeAddress?: string;
    homeCity?: string;
    homePostalCode?: string;
    nursingHomeAddress?: string;
    role?: string;
    isAdmin: boolean;
    members: any[];
    relativeCreateEmailId?: number;
};

export type UpdateGroupRequestAction = {
    type: typeof UPDATE_GROUP_REQUEST;
    payload: Partial<GroupUpdate>;
};

export type UpdateGroupSuccessAction = {
    type: typeof UPDATE_GROUP_SUCCESS;
    payload: Group;
};

export type UpdateGroupFailAction = {
    type: typeof UPDATE_GROUP_FAIL;
    payload: {
        error: any;
    };
};

// emails
export type Email = {
    email: string;
    firstName: string;
    templateId?: number;
};

export type SendEmailMemberRequestAction = {
    type: typeof SEND_EMAIL_MEMBER_REQUEST;
    payload: Email;
};

export type SendEmailMemberSuccessAction = {
    type: typeof SEND_EMAIL_MEMBER_SUCCESS;
    payload: Email;
};

export type SendEmailMemberSuccessFailAction = {
    type: typeof SEND_EMAIL_MEMBER_SUCCESS;
    payload: {
        error: any;
    };
};

export type SendEmailCustomerRequestAction = {
    type: typeof SEND_EMAIL_CUSTOMER_REQUEST;
    payload: Email;
};

export type SendEmailCustomerSuccessAction = {
    type: typeof SEND_EMAIL_CUSTOMER_SUCCESS;
    payload: Email;
};

export type SendEmailCustomerSuccessFailAction = {
    type: typeof SEND_EMAIL_CUSTOMER_SUCCESS;
    payload: {
        error: any;
    };
};

export type Mail = {
    name: string;
    email: string;
    text: string;
};

export type SendContactMailRequestAction = {
    type: typeof SEND_CONTACT_MAIL_REQUEST;
    payload: Mail;
};

export type SendContactMailSuccessAction = {
    type: typeof SEND_CONTACT_MAIL_SUCCESS;
    payload: Mail;
};

export type SendContactMailFailAction = {
    type: typeof SEND_CONTACT_MAIL_FAIL;
    payload: {
        error: any;
    };
};

// Get User Profile
export type GetUserEditFormDataRequestAction = {
    type: typeof GET_USER_EDIT_FORM_DATA_REQUEST;
    payload: any;
};

export type GetUserEditFormDataRequestSuccessAction = {
    type: typeof GET_USER_EDIT_FORM_DATA_SUCCESS;
    payload: any;
};

export type GetUserEditFormDataRequestFailAction = {
    type: typeof GET_USER_EDIT_FORM_DATA_FAIL;
    payload: any;
};

// Update User Profile
export type SetUserEditFormDataRequestAction = {
    type: typeof SET_USER_EDIT_FORM_DATA_REQUEST;
    payload: any;
};

export type SetUserEditFormDataRequestSuccessAction = {
    type: typeof SET_USER_EDIT_FORM_DATA_SUCCESS;
};

export type SetUserEditFormDataRequestFailAction = {
    type: typeof SET_USER_EDIT_FORM_DATA_FAIL;
    payload: any;
};

export type UserActions =
    | RegisterCustomerRequestAction
    | RegisterCustomerSuccessAction
    | RegisterCustomerFailAction
    | LoginUserRequestAction
    | LoginUserSuccessAction
    | LoginUserFailAction
    | LogoutUserAction
    | SetLoggedInAction
    | SetLoadingAction
    | GetUserRequestAction
    | GetUserSuccessAction
    | GetUserFailAction
    | UpdateUserRequestAction
    | UpdateUserSuccessAction
    | UpdateUserFailAction
    | UpdateGroupRequestAction
    | UpdateGroupSuccessAction
    | UpdateGroupFailAction
    | DeleteUserRequestAction
    | DeleteUserSuccessAction
    | DeleteUserFailAction
    | CreateGroupRequestAction
    | CreateGroupSuccessAction
    | CreateGroupFailAction
    | GetUsersRequestAction
    | GetUsersSuccessAction
    | GetUsersFailAction
    | SendEmailMemberRequestAction
    | SendEmailMemberSuccessAction
    | SendEmailMemberSuccessFailAction
    | SendEmailCustomerRequestAction
    | SendEmailCustomerSuccessAction
    | SendEmailCustomerSuccessFailAction
    | SendContactMailRequestAction
    | SendContactMailSuccessAction
    | SendContactMailFailAction
    | DeleteServiceRequestAction
    | DeleteServiceSuccessAction
    | DeleteServiceFailAction
    | GetOrdersRequestAction
    | GetOrdersSuccessAction
    | GetOrdersFailAction
    | GetUserEditFormDataRequestAction
    | GetUserEditFormDataRequestSuccessAction
    | GetUserEditFormDataRequestFailAction
    | SetUserEditFormDataRequestAction
    | SetUserEditFormDataRequestSuccessAction
    | SetUserEditFormDataRequestFailAction
    | ResetUsersAction
    | UpdateUserEditRequestAction
    | UpdateUserEditRequestSuccessAction
    | UpdateUserEditRquestFailAction;

// Services
export type Service = {
    id: number;
    name: string;
    description: string;
    price: number;
    category: string;
    shippingCategory: any;
    isActive: boolean;
    stripeProductId: string;
    stripeShippingRateId: string;
    stripeShippingRateIdFree: string;
    priceType: string;
    period: number | null | undefined;
    photoShow: boolean;
    photoLink: string | null | undefined;
    photoDescription: string | null | undefined;
};

export type ServiceItem = {
    id: any;
    sortNumber: any;
    name: string;
    descriptionFirst: string;
    descriptionSecond: string;
    price: number;
    category: string;
    shippingCategory: any;
    isActive: boolean;
    stripeProductId: string;
    stripeShippingRateId: string;
    stripeShippingRateIdFree: string;
    priceType: string;
    period: number | null | undefined;
    photoShow: boolean;
    photoLink: string | null | undefined;
    photoDescription: string | null | undefined;
};

export type AlertItem = {
    id: any;
    createdAt: any;
    updatedAt: any;
    text: string;
    start: any;
    end: any;
};

export type SeniorHomeItem = {
    id: any;
    createdAt: any;
    updatedAt: any;
    reference: string;
    name: string;
    show: boolean;
};

export type BlogItem = {
    id: any;
    createdAt: any;
    updatedAt: any;
    title: any;
    topics: any;
    cover: any;
    cover_type: any;
    content: any;
    content_preview: any;
    author_id: any;
    featured: any;
    url: any;
    cropX: any;
    cropY: any;
    cropWidth: any;
    cropHeight: any;
};

export type CouponItem = {
    id: any;
    createdAt: any;
    updatedAt: any;
    code: string;
    data: any;
};

export type CreateServiceRequestAction = {
    type: typeof CREATE_SERVICE_REQUEST;
    payload: {
        service: Service;
    };
};

export type CreateServiceSuccessAction = {
    type: typeof CREATE_SERVICE_SUCCESS;
    payload: {
        service: Service;
    };
};

export type CreateServiceFailAction = {
    type: typeof CREATE_SERVICE_FAIL;
    payload: {
        error: any;
    };
};

export type CreateAlertRequestAction = {
    type: typeof CREATE_ALERT_REQUEST;
    payload: {
        alert: AlertItem;
    };
};

export type CreateAlertSuccessAction = {
    type: typeof CREATE_ALERT_SUCCESS;
    payload: {
        alert: AlertItem;
    };
};

export type CreateAlertFailAction = {
    type: typeof CREATE_ALERT_FAIL;
    payload: {
        error: any;
    };
};

export type CreateSeniorHomeRequestAction = {
    type: typeof CREATE_SENIOR_HOME_REQUEST;
    payload: {
        senior_home: SeniorHomeItem;
    };
};

export type CreateSeniorHomeSuccessAction = {
    type: typeof CREATE_SENIOR_HOME_SUCCESS;
    payload: {
        senior_home: SeniorHomeItem;
    };
};

export type CreateSeniorHomeFailAction = {
    type: typeof CREATE_SENIOR_HOME_FAIL;
    payload: {
        error: any;
    };
};

export type CreateBlogRequestAction = {
    type: typeof CREATE_BLOG_REQUEST;
    payload: {
        blog: BlogItem;
    };
};

export type CreateBlogSuccessAction = {
    type: typeof CREATE_BLOG_SUCCESS;
    payload: {
        blog: BlogItem;
    };
};

export type CreateBlogFailAction = {
    type: typeof CREATE_BLOG_FAIL;
    payload: {
        error: any;
    };
};

export type CreateCouponRequestAction = {
    type: typeof CREATE_COUPON_REQUEST;
    payload: {
        coupon: CouponItem;
    };
};

export type CreateCouponSuccessAction = {
    type: typeof CREATE_COUPON_SUCCESS;
    payload: {
        coupon: CouponItem;
    };
};

export type CreateCouponFailAction = {
    type: typeof CREATE_COUPON_FAIL;
    payload: {
        error: any;
    };
};

export type GetServicesRequestAction = {
    type: typeof GET_SERVICES_REQUEST;
};

export type GetServicesSuccessAction = {
    type: typeof GET_SERVICES_SUCCESS;
    payload: Service[];
};

export type GetServicesFailAction = {
    type: typeof GET_SERVICES_FAIL;
    payload: {
        error: any;
    };
};

export type GetProductsRequestAction = {
    type: typeof GET_PRODUCTS_REQUEST;
    payload: string;
};
export type GetProductsSuccessAction = {
    type: typeof GET_PRODUCTS_SUCCESS;
    payload: ProductType[];
};
export type GetProductsFailAction = {
    type: typeof GET_PRODUCTS_FAIL;
    payload: {
        error: any;
    };
};
export type GetShippingRequestAction = {
    type: typeof GET_SHIPPING_REQUEST;
};
export type GetShippingSuccessAction = {
    type: typeof GET_SHIPPING_SUCCESS;
    payload: ShippingMethod[];
};
export type GetShippingFailAction = {
    type: typeof GET_SHIPPING_FAIL;
    payload: {
        error: any;
    };
};
export type UpdateProductsAction = {
    type: typeof UPDATE_PRODUCTS;
    payload: ProductType[];
};

export type GetCategoriesRequestAction = {
    type: typeof GET_CATEGORIES_REQUEST;
    payload: string;
};
export type GetCategoriesSuccessAction = {
    type: typeof GET_CATEGORIES_SUCCESS;
    payload: CategoryType;
};

export type GetCategoriesFailAction = {
    type: typeof GET_CATEGORIES_FAIL;
    payload: {
        error: any;
    };
};

export type GetAlertsRequestAction = {
    type: typeof GET_ALERTS_REQUEST;
};

export type GetAlertsSuccessAction = {
    type: typeof GET_ALERTS_SUCCESS;
    payload: AlertItem[];
};

export type GetAlertsFailAction = {
    type: typeof GET_ALERTS_FAIL;
    payload: {
        error: any;
    };
};

export type GetSeniorHomesRequestAction = {
    type: typeof GET_SENIOR_HOMES_REQUEST;
};

export type GetSeniorHomesSuccessAction = {
    type: typeof GET_SENIOR_HOMES_SUCCESS;
    payload: SeniorHomeItem[];
};

export type GetSeniorHomesFailAction = {
    type: typeof GET_SENIOR_HOMES_FAIL;
    payload: {
        error: any;
    };
};

export type GetBlogsRequestAction = {
    type: typeof GET_BLOGS_REQUEST;
};

export type GetBlogsSuccessAction = {
    type: typeof GET_BLOGS_SUCCESS;
    payload: BlogItem[];
};

export type GetBlogsFailAction = {
    type: typeof GET_BLOGS_FAIL;
    payload: {
        error: any;
    };
};

export type GetCouponsRequestAction = {
    type: typeof GET_COUPONS_REQUEST;
};

export type GetCouponsSuccessAction = {
    type: typeof GET_COUPONS_SUCCESS;
    payload: CouponItem[];
};

export type GetCouponsFailAction = {
    type: typeof GET_COUPONS_FAIL;
    payload: {
        error: any;
    };
};

export type ServicesActions =
    | GetServicesRequestAction
    | GetServicesSuccessAction
    | GetServicesFailAction
    | CreateServiceRequestAction
    | CreateServiceSuccessAction
    | CreateServiceFailAction
    | DeleteServiceRequestAction
    | DeleteServiceSuccessAction
    | DeleteServiceFailAction;

export type ProductsActions = GetProductsRequestAction | GetProductsSuccessAction | GetProductsFailAction | UpdateProductsAction;
export type CategoriesActions = GetCategoriesRequestAction | GetCategoriesSuccessAction | GetCategoriesFailAction;
export type ShippingMethodsActions = GetShippingRequestAction | GetShippingSuccessAction | GetShippingFailAction;

export type AlertsActions =
    | GetAlertsRequestAction
    | GetAlertsSuccessAction
    | GetAlertsFailAction
    | CreateAlertRequestAction
    | CreateAlertSuccessAction
    | CreateAlertFailAction
    | DeleteAlertRequestAction
    | DeleteAlertSuccessAction
    | DeleteAlertFailAction;

export type SeniorHomesActions =
    | GetSeniorHomesRequestAction
    | GetSeniorHomesSuccessAction
    | GetSeniorHomesFailAction
    | CreateSeniorHomeRequestAction
    | CreateSeniorHomeSuccessAction
    | CreateSeniorHomeFailAction
    | DeleteSeniorHomeRequestAction
    | DeleteSeniorHomeSuccessAction
    | DeleteSeniorHomeFailAction;

export type BlogsActions =
    | GetBlogsRequestAction
    | GetBlogsSuccessAction
    | GetBlogsFailAction
    | CreateBlogRequestAction
    | CreateBlogSuccessAction
    | CreateBlogFailAction
    | DeleteBlogRequestAction
    | DeleteBlogSuccessAction
    | DeleteBlogFailAction
    | ResetBlogAction;

export type CouponsActions =
    | GetCouponsRequestAction
    | GetCouponsSuccessAction
    | GetCouponsFailAction
    | CreateCouponRequestAction
    | CreateCouponSuccessAction
    | CreateCouponFailAction
    | DeleteCouponRequestAction
    | DeleteCouponSuccessAction
    | DeleteCouponFailAction
    | ResetCouponsAction;

// Service
export type AddToShoppingCartAction = {
    type: typeof ADD_TO_CART;
    payload: {
        service: Service;
    };
};

export type RemoveFromShoppingCartAction = {
    type: typeof REMOVE_FROM_CART;
    payload: {
        id: number;
    };
};

export type ResetShoppingCartAction = {
    type: typeof RESET_CART;
    payload: {};
};

export type ResetBlogAction = {
    type: typeof RESET_BLOG;
    payload: {};
};

export type ResetCouponsAction = {
    type: typeof RESET_COUPONS;
    payload: {};
};

export type ResetUsersAction = {
    type: typeof RESET_USERS;
    payload: {};
};

export type UpdateUserEditRequestAction = {
    type: typeof UPDATE_USER_EDIT_REQUEST;
    payload: Partial<UserUpdate>;
};

export type UpdateUserEditRequestSuccessAction = {
    type: typeof UPDATE_USER_EDIT_REQUEST_SUCCESS;
};

export type UpdateUserEditRquestFailAction = {
    type: typeof UPDATE_USER_EDIT_REQUEST_FAIL;
    payload: any;
};

export type ResetOrdersAction = {
    type: typeof RESET_ORDERS;
    payload: {};
};

// Shipping address
export type SaveShippingAddressAction = {
    type: typeof SAVE_SHIPPING_ADDRESS;
    payload: {
        data: any;
    };
};

export type SavePaymentMethodAction = {
    type: typeof SAVE_PAYMENT_METHOD;
    payload: {
        data: any;
    };
};

export type SaveStripePaymentMethodAction = {
    type: typeof SAVE_STRIPE_PAYMENT_METHOD;
    payload: {
        data: any;
    };
};

export type SetCouponAction = {
    type: typeof SET_COUPON;
    payload: any;
};

export type CartActions = AddToShoppingCartAction | RemoveFromShoppingCartAction | ResetShoppingCartAction | SaveShippingAddressAction | SavePaymentMethodAction | SaveStripePaymentMethodAction | SetCouponAction;

// Order
export type Order = {
    price: number;
    shippingPrice: number;
    address: string;
    postalCode: string;
    city: string;
    paymentMethod: any;
    stripePaymentMethod: any;
    taxPrice: number;
    totalPrice: number;
};

export type Admin = {
    paymentResult: string;
    isPaid: boolean;
    paidAt: string;
    isDelivered: boolean;
};

export type OrderActions = CreateOrderRequestAction | CreateOrderSuccessAction | CreateOrderFailAction | GetOrdersRequestAction | GetOrdersSuccessAction | GetOrdersFailAction | ResetOrdersAction;

export type CreateOrderRequestAction = {
    type: typeof CREATE_ORDER_REQUEST;
    payload: {
        order: Order;
    };
};

export type CreateOrderSuccessAction = {
    type: typeof CREATE_ORDER_SUCCESS;
    payload: any;
};

export type CreateOrderFailAction = {
    type: typeof CREATE_ORDER_FAIL;
    payload: {
        error: any;
    };
};

export type NurseActions =
    | GetNursesRequestAction
    | GetNursesSuccessAction
    | GetNursesFailAction
    | GetNurseProfileRequest
    | GetNurseProfileSuccess
    | GetNurseProfileFailed
    | GetAssignedSeniorListRequest
    | GetAssignedSeniorListSuccess
    | GetAssignedSeniorListFailed
    | GetAssignedSeniorRequest
    | GetAssignedSeniorSuccess
    | GetAssignedSeniorFailed
    | UpdateNurseRequestAction
    | UpdateNurseSuccessAction
    | UpdateNurseFailedAction
    | AddNurseRequestAction
    | AddNurseSuccessAction
    | AddNurseFailedAction
    | SignupNurseRequestAction
    | SignupNurseSuccessAction
    | SignupNurseFailedAction
    | ResetNurseMessageAction
    | SetNurseMessageAction
    | RemoveNurseRequestAction
    | RemoveNurseRequestSuccessAction
    | RemoveNurseRequestFailedAction;

export type GetNursesRequestAction = {
    type: typeof GET_NURSES_REQUEST;
};

export type GetNursesSuccessAction = {
    type: typeof GET_NURSES_SUCCESS;
    payload: any;
};

export type GetNursesFailAction = {
    type: typeof GET_NURSES_FAIL;
    payload: {
        error: any;
    };
};

export type GetNurseProfileRequest = {
    type: typeof GET_NURSE_PROFILE_REQUEST;
    payload: any;
};

export type GetNurseProfileSuccess = {
    type: typeof GET_NURSE_PROFILE_SUCCESS;
    payload: any;
};

export type GetNurseProfileFailed = {
    type: typeof GET_NURSE_PROFILE_FAILED;
    payload: any;
};

export type GetAssignedSeniorListRequest = {
    type: typeof ASSIGNED_SENIOR_LIST_REQUEST;
};

export type GetAssignedSeniorListSuccess = {
    type: typeof ASSIGNED_SENIOR_LIST_SUCCESS;
    payload: any;
};

export type GetAssignedSeniorListFailed = {
    type: typeof ASSIGNED_SENIOR_LIST_FAILED;
    payload: any;
};

export type GetAssignedSeniorRequest = {
    type: typeof ASSIGNED_SENIOR_REQUEST;
    payload: any;
};

export type GetAssignedSeniorSuccess = {
    type: typeof ASSIGNED_SENIOR_SUCCESS;
    payload: any;
};

export type GetAssignedSeniorFailed = {
    type: typeof ASSIGNED_SENIOR_FAILED;
    payload: any;
};

export type UpdateNurseRequestAction = {
    type: typeof UPDATE_NURSE_REQUEST;
    payload: any;
};

export type UpdateNurseSuccessAction = {
    type: typeof UPDATE_NURSE_SUCCESS;
};

export type UpdateNurseFailedAction = {
    type: typeof UPDATE_NURSE_FAILED;
    payload: any;
};

export type RemoveNurseRequestAction = {
    type: typeof DELETE_NURSE_REQUEST;
    payload: number;
};

export type RemoveNurseRequestSuccessAction = {
    type: typeof DELETE_NURSE_REQUEST_SUCCESS;
};

export type RemoveNurseRequestFailedAction = {
    type: typeof DELETE_NURSE_REQUEST_FAILED;
};

export type AddNurseRequestAction = {
    type: typeof ADD_NURSE_REQUEST;
    payload: any;
};

export type AddNurseSuccessAction = {
    type: typeof ADD_NURSE_SUCCESS;
};

export type AddNurseFailedAction = {
    type: typeof ADD_NURSE_FAILED;
    payload: any;
};

export type SignupNurseRequestAction = {
    type: typeof SIGNUP_NURSE_REQUSET;
    payload: any;
};

export type SignupNurseSuccessAction = {
    type: typeof SIGNUP_NURSE_SUCCESS;
};

export type SignupNurseFailedAction = {
    type: typeof SIGNUP_NURSE_FAILED;
    payload: any;
};

export type ResetNurseMessageAction = {
    type: typeof RESET_NURSE_MESSAGE;
};

export type SetNurseMessageAction = {
    type: typeof SET_NURSE_MESSAGE;
    payload: any;
};

export type ItSupportActions =
    | GetItSupportListRequestAction
    | GetItSupportListSuccessAction
    | GetItSupportListFailAction
    | GetItSupportProfileRequestAction
    | GetItSupportProfileSuccessAction
    | GetItSupportProfileFailAction
    | UpdateItSupportProfileRequestAction
    | UpdateItSupportProfileSuccessAction
    | UpdateItSupportProfileFailAction
    | SendInviteItSupportRequest
    | SendInviteItSupportSuccess
    | SendInviteItSupportFail
    | SignUpItSupportRequest
    | SignUpItSupportSuccess
    | SignUpItSupportFail
    | ResetItSupportMessageAction
    | SetItSupportMessageAction
    | RemoveItSupportProfileRequestAction
    | RemoveItSupportProfileRequestSuccessAction
    | RemoveItSupportProfileRequestFailedAction;

export type GetItSupportListRequestAction = {
    type: typeof GET_ITSUPPORT_LIST_REQUEST;
};

export type GetItSupportListSuccessAction = {
    type: typeof GET_ITSUPPORT_LIST_SUCCESS;
    payload: any;
};

export type GetItSupportListFailAction = {
    type: typeof GET_ITSUPPORT_LIST_FAIL;
    payload: any;
};

export type GetItSupportProfileRequestAction = {
    type: typeof GET_ITSUPPORT_PROFILE_REQUEST;
    payload: any;
};

export type GetItSupportProfileSuccessAction = {
    type: typeof GET_ITSUPPORT_PROFILE_SUCCESS;
    payload: any;
};

export type GetItSupportProfileFailAction = {
    type: typeof GET_ITSUPPORT_PROFILE_FAIL;
    payload: any;
};

export type UpdateItSupportProfileRequestAction = {
    type: typeof UPDATE_ITSUPPORT_PROFILE_REQUEST;
    payload: any;
};

export type UpdateItSupportProfileSuccessAction = {
    type: typeof UPDATE_ITSUPPORT_PROFILE_SUCCESS;
    payload: any;
};

export type UpdateItSupportProfileFailAction = {
    type: typeof UPDATE_ITSUPPORT_PROFILE_FAIL;
    payload: any;
};

export type SendInviteItSupportRequest = {
    type: typeof SEND_INVITE_ITSUPPORT_REQUEST;
    payload: any;
};

export type SendInviteItSupportSuccess = {
    type: typeof SEND_INVITE_ITSUPPORT_SUCCESS;
};

export type SendInviteItSupportFail = {
    type: typeof SEND_INVITE_ITSUPPORT_FAIL;
    payload: any;
};

export type SignUpItSupportRequest = {
    type: typeof SIGNUP_ITSUPPORT_REQUEST;
    payload: any;
};

export type SignUpItSupportSuccess = {
    type: typeof SIGNUP_ITSUPPORT_SUCCESS;
};

export type SignUpItSupportFail = {
    type: typeof SIGNUP_ITSUPPORT_FAIL;
    payload: any;
};

export type ResetItSupportMessageAction = {
    type: typeof RESET_ITSUPPORT_MESSAGE;
};

export type SetItSupportMessageAction = {
    type: typeof SET_ITSUPPORT_MESSAGE;
    payload: any;
};

export type RemoveItSupportProfileRequestAction = {
    type: typeof DELETE_IT_SUPPORT_PROFILE_REQUEST;
    payload: number;
};

export type RemoveItSupportProfileRequestSuccessAction = {
    type: typeof DELETE_IT_SUPPORT_PROFILE_REQUEST_SUCCESS;
};

export type RemoveItSupportProfileRequestFailedAction = {
    type: typeof DELETE_IT_SUPPORT_PROFILE_REQUEST_FAILED;
};

export type VideoMeetActions = JoinRoomRequestAction | JoinRoomRequestFailAction | JoinRoomRequestSuccessAction | ResetVideoMeetAction | SetLkTokenAction | SetPreJoinTokenAction | RemoveRoomNameAction;

export type JoinRoomRequestAction = {
    type: typeof JOIN_ROOM_REQUEST;
    payload: any;
};

export type JoinRoomRequestFailAction = {
    type: typeof JOIN_ROOM_REQUEST_FAIL;
    payload: any;
};

export type JoinRoomRequestSuccessAction = {
    type: typeof JOIN_ROOM_REQUEST_SUCCESS;
    payload: any;
};

export type ResetVideoMeetAction = {
    type: typeof RESET_VIDEO_MEET;
};

export type SetLkTokenAction = {
    type: typeof SET_LK_TOKEN;
    payload: any;
};

export type SetPreJoinTokenAction = {
    type: typeof SET_PRE_JOIN_TOKEN;
    payload: any;
};

export type RemoveRoomNameAction = {
    type: typeof REMOVE_ROOM_NAME;
    payload: any;
};

// States
export type CredentialState = {
    credential: Credential;
};

export type Member = {
    id: any;
    role: string;
    username: string;
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    mobileNumber?: string;
    skypeEmail?: string;
    image?: string;
    nursingHome?: string;
    department?: string;
    homeAddress?: string;
    homeCity?: string;
    homePostalCode?: string;
    nursingHomeAddress?: string;
    isAdmin?: boolean;
    chosenService?: string;
    autoAnswer?: string;
    tabletLanguage?: string;
    serviceOption?: string;
};

export type Group = {
    id: number | null;
    name: string;
    members: Member[];
};

export type GroupState = {
    id: number | null;
    name: string;
    members: Member[];
    success: boolean;
};

export type PickupPoint = {
    address1: string | null;
    address2: string | null;
    city: string | null;
    contact: string | null;
    countryCode: string | null;
    email: string | null;
    fax: string | null;
    id: string | null;
    name: string | null;
    openingHours: string | null;
    phone: string | null;
    serviceCode: string | null;
    serviceType: string | null;
    sms: string | null;
    state: string | null;
    zipcode: string | null;
};

export type CartState = {
    usePickupPoint: boolean;
    pickupPoint: PickupPoint;
    inCart: ProductType[];
    shippingMethod: string;
    name: string;
    address: string;
    postalCode: string;
    city: string;
    country: string;
    email: string;
    mobile: string;
    paymentMethod: any;
    stripePaymentMethod: any;
    price: number;
    shippingPrice: number;
    taxPrice: number;
    totalPrice: number;
    coupon: any;
    couponData: any;
    nursingHome: any;
};

export type ServicesState = {
    list: any[];
    loading: boolean;
    error: any;
};

export type ProductsState = {
    products: ProductType[];
    loading: boolean;
    error: any;
};

export type CategoriesState = {
    category?: CategoryType;
    loading: boolean;
    error: any;
};

export type ShippingMethodsState = {
    shipping?: ShippingMethod[];
    loading: boolean;
    error: any;
};

export type AlertsState = {
    list: any[];
    loading: boolean;
    deleted_at: any;
    error: any;
};

export type SeniorHomesState = {
    list: any[];
    loading: boolean;
    deleted_at: any;
    error: any;
};

export type BlogsState = {
    list: any[];
    loading: boolean;
    deleted_at: any;
    error: any;
};

export type CouponsState = {
    list: any[];
    loading: boolean;
    deleted_at: any;
    error: any;
};

export type Resources = {
    services?: ServiceItem[];
    loading: boolean;
    deleting: boolean;
    deleted_at: number;
    error: any;
};

export type Nurses = {
    list: any[];
    loading: boolean;
    error: any;
    profile: {};
    seniorList: [];
    message: {};
};

export type ItSupport = {
    list: any[];
    loading: boolean;
    error: any;
    itSupportProfile: {};
    message: {};
};

export type VideoMeet = {
    loading: boolean;
    error: any;
    message: {};
    participants: any[];
};

export type AppState = {
    user: UserState;
    users: Users;
    cart: CartState;
    services: ServicesState;
    alerts: AlertsState;
    blogs: BlogsState;
    coupons: CouponsState;
    resources: Resources;
    orders: Orders;
    seniorHomes: SeniorHomesState;
    nurses: Nurses;
    itSupport: ItSupport;
    videoMeet: VideoMeet;
    products: ProductsState;
    categories: CategoriesState;
    shippingMethods: ShippingMethodsState;
};

export type CMSProductType = {
    id: number;
    attributes: {
        Name?: string;
        SortNumber: number;
        StripeProductId?: string;
        StripeProductTestId?: string;
        PriceType?: string;
        Period?: number;
        Active?: boolean;
        Price?: number;
        ShowNameLine?: boolean;
        createdAt: string;
        updatedAt: string;
        publishedAt: string;
        Locked?: boolean;
        category: {
            data: {
                id: number;
                attributes: {
                    slug: string;
                };
            };
        };
        FirstBox: FirstBoxType;
        SecondBox: BlocksContent;
        ThirdBox: BlocksContent;
        Emphasized: boolean;
        Selected: boolean;
    };
};

// export type SubCategoriesSlugs = "tablet" | "tablet-stand" | "alarm-extra-gadget" | "alarm-type" | "alarm-set-up-service" | "alarm-operator" | "phone" | "phone-set-up-service" | "phone-operato" | "phone-service-payment" | "phone-gadget";

export type CMSImageType = {
    data: {
        id: number;
        attributes: {
            url?: string;
        };
    };
};

export type ImageBoxType = {
    id: number;
    __component: "boxes.image";
    Image: CMSImageType;
};

export type DynamicTextType = {
    id: number;
    __component: "boxes.dynamic-text";
    Content: BlocksContent;
};

export type FirstBoxComponentType = ImageBoxType | DynamicTextType;
export type FirstBoxType = FirstBoxComponentType[];

export type SubCategoryType = {
    id: number;
    attributes: {
        slug: string;
        Name: string;
        Selection: "Single" | "Multiple";
        Description: BlocksContent;
        SortNumber: number;
        Optional: boolean;
    };
};

export type CategoryType = {
    id: number;
    attributes: {
        slug: string;
        Name: string;
        sub_categories?: {
            data: SubCategoryType[];
        };
        OrderConfirmationNoCardPayer?: number;
        OrderProcessConfirmationToCustomer?: number;
        RelativeCreateAccount?: number;
        RelativeAccountDone?: number;
    };
};

export type ProductType = {
    id: number;
    name?: string;
    category: string;
    image?: string;
    price?: number;
    isActive?: boolean;
    shippingCategory?: string;
    stripeProductId?: string;
    stripeShippingRateId?: string;
    stripeShippingRateIdFree?: string;
    priceType?: string;
    period?: number;
    sortNumber?: number;
    showNameLine?: boolean;
    locked?: boolean;
    firstBox: FirstBoxType;
    secondBox: BlocksContent;
    thirdBox: BlocksContent;
    emphasized?: boolean;
    selected?: boolean;
};

export type CMSShippingMethod = {
    id: string;
    attributes: {
        Name: string;
        ShippingMethod: string;
        StripeShippingRateId_Test: string;
        StripeFreeShippingRateId_Test: string;
        StripeShippingRateId_Prod: string;
        StripeFreeShippingRateId_Prod: string;
        Price: number;
    };
};
export type ShippingMethod = {
    id: string;
    name: string;
    shippingMethod: string;
    stripeShippingRateId: string;
    stripeFreeShippingRateId: string;
    price: number;
};
